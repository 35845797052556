export const GermanTranslation = {
  nav: {
    openapi: "API Dokumentation",
    categoryUser: "Benutzereinstellungen",
    theme: 'Design',
    language: "Sprache",
    logout: "Ausloggen",
  },
  language: {
    title: "Sprache auswählen",
    intro: "Welche Sprache möchten Sie benutzen? Diese Einstellung hat keinen Einfluss auf andere Benutzer.",
    english: "Englisch",
    german: "Deutsch"
  },
  telecom: {
    title: "Q-Telecom",
  },
}
