export const EnglishTranslation = {
  nav: {
    openapi: "API Documentation",
    categoryUser: "User Settings",
    theme: 'Theme',
    language: "Language",
    logout: "Log out"
  },
  language: {
    title: "Choose your language",
    intro: "What language would you like to use? This setting does not affect other users.",
    english: "English",
    german: "German (Deutsch)"
  },
  telecom: {
    title: "Q-Telecom API",
  },
}
